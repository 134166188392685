import { Component } from '@angular/core';
import {ChangeRequest} from "../open-requests/open-requests.component";


const CHANGE_REQUEST_DATA: ChangeRequest[] = [
  {id: 746, subject: 'Test', workflow: 'BWI-Rule-Add-Parallel-Approval-Workflow', status: 'Closed', owner: 'Not Assigned', created: '4 months ago', lastUpdated: '3 months ago'},
  {id: 745, subject: 'Test', workflow: 'BWI-Rule-Add-Parallel-Approval-Workflow', status: 'Closed', owner: 'Not Assigned', created: '4 months ago', lastUpdated: '4 months ago'},
];

@Component({
  selector: 'app-closes-requests',
  templateUrl: './closed-requests.component.html',
  styleUrl: './closed-requests.component.scss'
})
export class ClosedRequestsComponent {
  displayedColumns: string[] = ['id', 'subject', 'workflow', 'status', 'owner', 'created', 'lastUpdated'];
  dataSource = CHANGE_REQUEST_DATA;

}
